<template>
	<div class="brwBox">
		<div>
			<el-form ref="form" :inline="true" :model="queryForm">
				<el-form-item>
					<el-input placeholder="请输入关键字" v-model="queryForm.keyword" class="input-with-select">
						<el-select v-model="queryForm.type" slot="prepend" placeholder="请选择" style="width: 150px;">
							<el-option label="关键字类型" value=""></el-option>
							<el-option label="资产名称或申请人" value="title"></el-option>
							
							<el-option label="所在位置" value="zc_where"></el-option>
						</el-select>
						<el-button slot="append" icon="el-icon-search" @click="getList" type="primary"></el-button>
					</el-input>
					
				</el-form-item>
				<el-form-item style="float: right;" v-if="false">
					<el-button  icon="el-icon-plus">增加借用</el-button>
					
					</el-form-item>
				
			</el-form>

		</div>
		<el-table :data="DataList" style="width: 100%;" stripe border >
			<el-table-column type="index" label="编号" width="60">
			</el-table-column>
			<el-table-column prop="status" label="当前状态" width="140">
				<template slot-scope="scope">
					<span v-if="scope.row.sp_status==0&&scope.row.status==0" class="st0">未审核</span>
					<span v-if="scope.row.sp_status==1&&scope.row.status==1" class="st1">审核通过待签收</span>
					<span v-if="scope.row.sp_status==1&&scope.row.status==2" class="st2">已签收待归还</span>
					<span v-if="scope.row.sp_status==1&&scope.row.status==3" class="st3">归还申请中</span>
					<span v-if="scope.row.sp_status==1&&scope.row.status==4" class="st4">归还审核不通过</span>
					<span v-if="scope.row.sp_status==1&&scope.row.status==5" class="st5">已归还</span>
					<span v-if="scope.row.sp_status==2" class="st6">审核不通过</span>

				</template>
			</el-table-column>

			<el-table-column prop="title" label="表单名称" min-width="120">
				<template slot-scope="scope">
					<span style="color: #409EFF; cursor: pointer;" @click="showInfo(scope.row)">{{scope.row.title}}</span>
				
				</template>
			</el-table-column>
			<el-table-column prop="name" label="申请人" width="100"></el-table-column>
			<el-table-column prop="start_date" label="借用时间" width="200">
				<template slot-scope="scope">
					<span>{{scope.row.start_date.split(" ")[0]}}</span> 至
					<span>{{scope.row.end_date.split(" ")[0]}}</span>

				</template>
			</el-table-column>

			<el-table-column prop="cdate" label="申请时间" width="160">
                <template slot-scope="scope">
                    <span>{{scope.row.cdate?scope.row.cdate.substr(0,10):'-'}}</span>
                </template>
            </el-table-column>
			<el-table-column prop="remark" label="备注" width="120"></el-table-column>
			<el-table-column prop="status" label="操作" width="160" fixed="right">
				<template slot-scope="scope">
					<el-link type="primary"  @click="showInfo(scope.row)">详情</el-link>
					<el-link style="margin-left: 10px;" type="primary" v-if="scope.row.sp_status==0&&scope.row.status==0"  @click="showSP(scope.row,0,'借用单审核')">审核</el-link>
					
					<el-link  style="margin-left: 10px;"  type="primary" v-if="scope.row.status==3"  @click="dialogVisible2=true">签收</el-link>
					
					
                </template>
			</el-table-column>
		</el-table>
		<Pagination :pagination="page" @paginate="paginate"></Pagination>
		
		
		
		<el-dialog title="申请详情" :visible.sync="dialogVisible" :close-on-click-modal="false" width="800px" custom-class="width_800 cus_dialog">
				<div v-for="(info,idx) in DataInfo" :key="idx">
					<table class="pntable" cellspacing="0">
						<tr>
							<td style="width: 20%;"  class="r1td ct">申请人</td>
							<td class="r1td rlsttd" style="width: 80%;">{{info.czname}}-{{info.dept_name}}</td>
						</tr>
						<tr>
							<td style="width: 20%;"  class="nmtd ct">借用物品</td>
							<td class="nmtd rlsttd" style="width: 80%;">
								<span v-for="(zc,i) in info.zclist" style="margin-left: 10px; color: #409EFF; cursor: pointer;" @click="showZcInfo(zc)">{{zc.title}}</span>
							</td>
						</tr>
						<tr>
							<td style="width: 20%;"  class="nmtd ct">借用日期</td>
							<td class="nmtd rlsttd" style="width: 80%;">{{info.start_date}} 至 {{info.end_date}}</td>
						</tr>
						<tr>
							<td style="width: 20%;"  class="nmtd ct">借用后置于何处</td>
							<td class="nmtd rlsttd" style="width: 80%;">{{info.zc_where}}</td>
						</tr>
						<tr>
							<td style="width: 20%;"  class="nmtd ct">备注</td>
							<td class="nmtd rlsttd" style="width: 80%;">{{info.remark}}</td>
						</tr>
						<tr>
							<td style="width: 20%;"  class="nmtd ct">附件</td>
							<td class="nmtd rlsttd" style="width: 80%;">
								<div  >
									<!-- <a href="javascript:;" @click="uploadPreview(f)">{{f.filename}}</a> -->
									<div class="updimage" v-for="(f,idx) in info.files" :key="idx"  :style="{backgroundImage:'url('+f.src+')'}">
										<img :src="f.src"  onclick="viewImage(this)" style="opacity: 0;"/>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td style="width: 20%;"  class="nmtd ct">申请日期</td>
							<td class="nmtd rlsttd" style="width: 80%;">{{info.cdate}}</td>
						</tr>
						<tr v-if="info.handsign">
							<td style="width: 20%;"  class="nmtd ct">签收签名</td>
							<td class="nmtd rlsttd" style="width: 80%;">
							<img :src="info.handsign"  onclick="viewImage(this)" style="width: 112px; height: 60px; border: 1px solid #f6f6f6;" />
							</td>
						</tr>
						<tr v-if="info.back_confirm_date">
							<td style="width: 20%;"  class="nmtd ct">归还日期</td>
							<td class="nmtd rlsttd" style="width: 80%;">{{info.back_confirm_date}}</td>
						</tr>
						<tr v-if="info.back_confirm_sign">
							<td style="width: 20%;"  class="nmtd ct">归还确认签名</td>
							<td class="nmtd rlsttd" style="width: 80%;">
							<img :src="info.back_confirm_sign"  onclick="viewImage(this)" style="width: 112px; height: 60px; border: 1px solid #f6f6f6;" />
							</td>
						</tr>
						<tr>
							<td style="width: 20%;"  class="nmtd ct">当前状态</td>
							<td class="nmtd rlsttd" style="width: 80%;">
								
								<span v-if="info.sp_status==0&&info.status==0" class="st0">未审核</span>
								<span v-if="info.sp_status==1&&info.status==1" class="st1">审核通过待签收</span>
								<span v-if="info.sp_status==1&&info.status==2" class="st2">已签收待归还</span>
								<span v-if="info.sp_status==1&&info.status==3" class="st3">归还申请中</span>
								<span v-if="info.sp_status==1&&info.status==4" class="st4">归还审核不通过</span>
								<span v-if="info.sp_status==1&&info.status==5" class="st5">已归还</span>
								<span v-if="info.sp_status==2" class="st6">审核不通过</span>
							</td>
						</tr>
						<tr >
							<td style="width: 20%;"  class="nmtd ct">扫码操作</td>
							<td class="nmtd rlsttd" style="width: 80%;">
								<img :src="'./static/images/zichan.png'" style="width: 200px;" />
							</td>
						</tr>
						
					</table>
				</div>
				
		</el-dialog>
		
		<el-dialog title="请在手机端操作" :visible.sync="dialogVisible2" width="200px" style="height: 550px;" top="30vh"
		 custom-class="width_800 cus_dialog">
			<img :src="'./static/images/zichan.png'" style="width: 100%;" />
		</el-dialog>
		
		<zcinfo :id="zc_id" v-if="dialogInfoShow"></zcinfo>
        <el-dialog :title="title_sp" :visible.sync="dialogVisible_sp" :close-on-click-modal="false" width="800px" custom-class="width_800 cus_dialog">
            <div class="titleBar flexStart" style="margin-bottom: 10px;">
                <div class="div flexStart">基本信息</div>
            </div>
            <div v-for="(info,idx) in DataInfo" :key="idx">
                <table class="pntable" cellspacing="0">
                    <tr>
                        <td style="width: 20%;"  class="r1td ct">申请人</td>
                        <td class="r1td rlsttd" style="width: 80%;">{{info.czname}}-{{info.dept_name}}</td>
                    </tr>
                    <tr>
                        <td style="width: 20%;"  class="nmtd ct">借用物品</td>
                        <td class="nmtd rlsttd" style="width: 80%;">
                            <span v-for="(zc,i) in info.zclist" style="margin-left: 10px; color: #409EFF; cursor: pointer;" @click="showZcInfo(zc)">{{zc.title}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 20%;"  class="nmtd ct">借用日期</td>
                        <td class="nmtd rlsttd" style="width: 80%;">{{info.start_date}} 至 {{info.end_date}}</td>
                    </tr>
                    <tr>
                        <td style="width: 20%;"  class="nmtd ct">借用后置于何处</td>
                        <td class="nmtd rlsttd" style="width: 80%;">{{info.zc_where}}</td>
                    </tr>
                    <tr>
                        <td style="width: 20%;"  class="nmtd ct">备注</td>
                        <td class="nmtd rlsttd" style="width: 80%;">{{info.remark}}</td>
                    </tr>
                    <tr>
                        <td style="width: 20%;"  class="nmtd ct">附件</td>
                        <td class="nmtd rlsttd" style="width: 80%;">
                            <div  >
                                <!-- <a href="javascript:;" @click="uploadPreview(f)">{{f.filename}}</a> -->
                                <div class="updimage" v-for="(f,idx) in info.files" :key="idx"  :style="{backgroundImage:'url('+f.src+')'}">
                                    <img :src="f.src"  onclick="viewImage(this)" style="opacity: 0;"/>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 20%;"  class="nmtd ct">申请日期</td>
                        <td class="nmtd rlsttd" style="width: 80%;">{{info.cdate}}</td>
                    </tr>
                    <tr v-if="info.handsign">
                        <td style="width: 20%;"  class="nmtd ct">签收签名</td>
                        <td class="nmtd rlsttd" style="width: 80%;">
                            <img :src="info.handsign"  onclick="viewImage(this)" style="width: 112px; height: 60px; border: 1px solid #f6f6f6;" />
                        </td>
                    </tr>
                    <tr v-if="info.back_confirm_date">
                        <td style="width: 20%;"  class="nmtd ct">归还日期</td>
                        <td class="nmtd rlsttd" style="width: 80%;">{{info.back_confirm_date}}</td>
                    </tr>
                    <tr v-if="info.back_confirm_sign">
                        <td style="width: 20%;"  class="nmtd ct">归还确认签名</td>
                        <td class="nmtd rlsttd" style="width: 80%;">
                            <img :src="info.back_confirm_sign"  onclick="viewImage(this)" style="width: 112px; height: 60px; border: 1px solid #f6f6f6;" />
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 20%;"  class="nmtd ct">当前状态</td>
                        <td class="nmtd rlsttd" style="width: 80%;">
                            
                            <span v-if="info.sp_status==0&&info.status==0" class="st0">未审核</span>
                            <span v-if="info.sp_status==1&&info.status==1" class="st1">审核通过待签收</span>
                            <span v-if="info.sp_status==1&&info.status==2" class="st2">已签收待归还</span>
                            <span v-if="info.sp_status==1&&info.status==3" class="st3">归还申请中</span>
                            <span v-if="info.sp_status==1&&info.status==4" class="st4">归还审核不通过</span>
                            <span v-if="info.sp_status==1&&info.status==5" class="st5">已归还</span>
                            <span v-if="info.sp_status==2" class="st6">审核不通过</span>
                        </td>
                    </tr>
                    
                
                </table>
            </div>
            <div class="titleBar flexStart"  style="margin-top: 20px;">
                <div class="div flexStart">审批信息</div>
            </div>
            <el-form v-if="EditItem.status==0">
                <el-form-item label="原因:">
                    <el-input v-model="EditItem.spword" size="mini" placeholder="可填写审批不通过原因"></el-input>
                </el-form-item>
            </el-form>
			
            <div class="titleBar flexStart"  style="margin-top: 20px;">
                <div class="div flexStart">签名信息</div>
            </div>
            <el-form>
                <el-form-item label="手写签名:">
                    <el-upload
                        action="" :http-request="uploadOss"
                        :on-success="uploadCover"
                        :show-file-list="false" :file-list="files" name="image">
                        <el-button size="mini" plain icon="el-icon-upload2" type="primary"
                                   style="margin-left: 10px;">点击上传
                        </el-button>
                    </el-upload>
                    <div class="flexStart">
                        <img :src="EditItem.handsign" class="avatar" style="display:inline-block;width: 144px;">
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <div v-if="EditItem.curStatus==0">
                    <el-button type="primary" @click="saveForm(EditItem.curStatus,1)">同意</el-button>
    
                    <el-button type="primary" plain @click="saveForm(EditItem.curStatus,0)">不同意</el-button>

                </div>
            </div>
        </el-dialog>
    
    </div>
</template>
<script>
	import Pagination from '../../com/pagination.vue'
	import Deptuser from '../../com/deptuser.vue'
	import zcinfo from './tmplt/zcinfo.vue'
	export default {
		components: {
			Pagination,
			Deptuser,
			zcinfo
		},
		data() {
			return {
				userinfo: {
					union_id: ""
				},
				queryForm: {
					type: "",
					keyword: ""
				},
				DataList: [],
				page: {
					current_page: 1,
					pre_page: 10,
					count: 0
				},
				dialogVisible:false,
				DataInfo:[],
				dialogInfoShow:false,
				zc_id:0,
                //审批单 status 0123
				dialogVisible_sp:false,
				dialogVisible2:false,
				title_sp:'审批',
				files: [],
				EditItem:{
					spword:"",
					status:0
				},
			}
		},
		mounted() {
			this.userinfo = JSON.parse(localStorage.getItem("user"))
			this.getList()
		},
		methods: {
			closeDialog() {
				this.$parent.showFromlistDialog = false
			},
			getList() {
				this.$http.post("/api/zc_from_splsit", {
					type: 'borrow_sp',
					page: this.page.current_page,
					pagesize: this.page.pre_page,
					ktype:this.queryForm.type,
					keyword:this.queryForm.keyword
				}).then(res => {

					this.DataList = res.data.data;
					this.page = res.data.page;

				})
			},
			paginate(val) {
				this.page.current_page = val
				this.getList();
			},
			showInfo(item){
				
				
				this.$http.post("/api/zc_use_list",{id:item.id}).then(res=>{
					res.data.start_date = res.data.start_date.split(' ')[0];
					res.data.end_date = res.data.end_date.split(' ')[0];
					try{
						res.data.files = JSON.parse(res.data.files)
					}catch(e){
						res.data.files=[];
					}
					
					this.DataInfo = [res.data];
					this.EditItem = res.data
					this.dialogVisible = true;
				})
			},
			showZcInfo(zc){
				this.zc_id = zc.id;
				this.dialogInfoShow=true
			},
			showSP(row,status,title){
                if(status == 0){
					this.$http.post("/api/zc_use_list",{id:row.id}).then(res=>{
						res.data.start_date = res.data.start_date.split(' ')[0];
						res.data.end_date = res.data.end_date.split(' ')[0];
						try{
							res.data.files = JSON.parse(res.data.files)
						}catch(e){
							res.data.files=[];
						}

						this.DataInfo = [res.data];
					})
                }
				this.title_sp = title
				this.dialogVisible_sp = true
				this.EditItem.curStatus = status
			},
			saveForm(curStatus,spStatus){
			if(!this.EditItem.handsign){
				this.$message.error('未传签名！')
				return
			}
			console.log(this.DataInfo[0])
				let data = {
					spword: this.EditItem.spword,
					id: this.DataInfo[0].id,
					spusers: [],
					signimg: this.EditItem.handsign,
					step_type:"and" ,
					type: 1,
					code: this.userinfo.union_id ,
					complete: spStatus,
					is_end:0
				}
				
				
				this.$http.post("/api/zc_borrow_sp",data).then(res=>{
					this.$message.success('审批成功！')
					this.getList();
					this.dialogVisible_sp = false;
				})
            },
			uploadCover (e) {
				let _this = this
				console.log(e)
				// this.files.push({filename: e.fileName, src: e.src, name: e.fileName, url: e.src})
				this.EditItem.handsign = e.src
				this.EditItem.back_confirm_sign = e.src
				this.EditItem = {..._this.EditItem}
			},

		}
	}
</script>
<style scoped="scoped" type="text/scss" lang="less">
    .brwBox{
        .el-dropdown-link {
            cursor: pointer;
            color: #409EFF;
            font-size: 12px;
        }
        .el-icon-arrow-down {
            font-size: 12px;
        }
    }
	.bqitem {
		display: inline-block;
		width: 200px;
		margin-right: 70px;
		margin-top: -120px;
		margin-bottom: 50px;
		transform: rotateZ(-90deg);
		transform-origin: left top;
	}
	.updimage{
		width: 50px; height: 50px; display: inline-block; vertical-align: top; margin: 0 10px 10px 0; border: 1px dotted #ccc;
		background-size: 100% auto;
		background-position: center;
		overflow: hidden;
		background-repeat: no-repeat;
		cursor: pointer;
	}
</style>
